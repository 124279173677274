import { CustomValidatorsModule } from './custom-validators/custom-validators.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { RequestInterceptor } from './utils/request-interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    CustomValidatorsModule,
  ],
  exports: [
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    CustomValidatorsModule,
  ]
})
export class SharedModule {
  constructor() {
    library.add(fas, far);
  }
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true
        }
      ]
    };
  }
}
