import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RegisterModel } from '../models/register';
import { environment } from 'src/environments/environment';
import { LoginModel } from '../models/login';
import { User } from '../models/user';
import { PasswordResetModel } from '../models/password-reset.model';
import { PasswordSetModel } from '../models/password-set.model';


@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private httpClient: HttpClient) { }

  public register(model: RegisterModel): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/register/`, model);
  }

  public login(model: LoginModel): Observable<{key: string}> {
    return this.httpClient.post<{key: string}>(`${environment.apiUrl}/auth/login/`, model);
  }

  public getUser(): Observable<User> {
    return this.httpClient.get<User>(`${environment.apiUrl}/auth/user/`);
  }

  public resetPassword(model: PasswordResetModel): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/auth/password/reset/`, model);
  }

  public setPassword(model: PasswordSetModel): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/auth/password/reset/confirm/`, model);
  }
}
