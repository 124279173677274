import { AuthApiService } from './auth-api.service';
import { Injectable } from '@angular/core';
import { Observable, Subscription, of, Subject, BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // tslint:disable-next-line:variable-name
  private _token: string;

  // tslint:disable-next-line:variable-name
  private _user: User;

  public set user(value: User) {
    this._user = value;
    if (value == null) {
      localStorage.removeItem('idexx_user');
    } else {
      localStorage.setItem('idexx_user', JSON.stringify(value));
    }
  }

  public get user(): User {
    return this._user;
  }

  public set token(value: string) {
    this._token = value;
    if (value == null) {
      localStorage.removeItem('idexx_token');
    } else {
      localStorage.setItem('idexx_token', value);
    }
  }

  public get token(): string {
    return this._token;
  }

  public get isAuthorized(): boolean {
    return this.token != null
      && this.user != null;
  }

  public logout(): void {
    this.token = null;
    this.user = null;
  }

  constructor(
    private authApi: AuthApiService
  ) {
    this.token = localStorage.getItem('idexx_token');
    this.user = JSON.parse(localStorage.getItem('idexx_user'));
  }
}
