import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailValidatorDirective } from './email-validator.directive';
import { ValuesEqualValidator } from './values-equal-validator.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    EmailValidatorDirective,
    ValuesEqualValidator,
  ],
  exports: [
    EmailValidatorDirective,
    ValuesEqualValidator,
  ]
})
export class CustomValidatorsModule {}
