import { AuthService } from './../../account/services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { takeLast, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json; indent=4'
    });

    if (!req.url.startsWith(environment.apiUrl)) {
      return next.handle(req);
    }

    req = req.clone({ headers });

    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Token ${token}`)
      });
    }

    return next.handle(req);
  }
}
