import { Directive, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[valuesEqual][formControlName],[valuesEqual][formControl],[valuesEqual][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValuesEqualValidator,
      multi: true
    }
  ]
})

export class ValuesEqualValidator implements Validator {
  private get isReverse() {
    if (!this.reverse) {
      return false;
    }
    return this.reverse === 'true' ? true : false;
  }

  constructor(
    @Attribute('valuesEqual') public controlName,
    @Attribute('reverse') public reverse: string
  ) { }


  validate(control: AbstractControl): { [key: string]: any } | null {
    const a = control.value;
    const b = control.root.get(this.controlName);

    if (b && a !== b.value && !this.isReverse) {
      return {
        valuesEqual: false
      };
    }

    if (b && a === b.value && this.isReverse) {
      delete b.errors['valuesEqual'];
      if (!Object.keys(b.errors).length) {
        b.setErrors(null);
      }
    }

    // value not equal and reverse
    if (b && a !== b.value && this.isReverse) {
        b.setErrors({ valuesEqual: false });
    }

    return null;
  }
}
